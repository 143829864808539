.mq-accordion-item {
  width: 100%;

  .mq-accordion-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    gap: var(--gl-spacing-scale-3);
    border: 0;
    position: relative;

    &.centered {
      justify-content: center;

      .mq-accordion-toggle__content {
        padding: var(--gl-spacing-scale-3) 2rem;
      }
    }

    &.right {
      .mq-accordion-toggle__arrow {
        right: 0;
      }

      &:not(.centered) {
        .mq-accordion-toggle__content {
          padding: var(--gl-spacing-scale-3) 2rem var(--gl-spacing-scale-3) var(--gl-spacing-scale-3);
        }
      }
    }

    &.left {
      .mq-accordion-toggle__arrow {
        left: 0;
      }

      &:not(.centered) {
        .mq-accordion-toggle__content {
          padding: var(--gl-spacing-scale-3) var(--gl-spacing-scale-3) var(--gl-spacing-scale-3) 2rem;
        }
      }
    }

    .mq-accordion-toggle__content {
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--gl-spacing-scale-3);
      font-size: var(--mq-font-size-scale-3);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .mq-accordion-toggle__arrow {
      width: 2rem;
      aspect-ratio: 1/1;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;

      &.active {
        transform: rotateX(180deg);
      }
    }
  }

  .mq-accordion-collapse {
    width: 100%;
    height: auto;
    overflow: hidden;
    transition: 0.3s all ease-in-out;

    &:not(.compact) {
      .mq-accordion-collapse__content {
        padding: var(--gl-spacing-scale-3);
      }
    }

    &:not(.collapsed) {
      opacity: 0;
      overflow: hidden;
      padding: 0;
      height: 0;
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: var(--color-info-disabled-dim);
    border-color: var(--color-neutral-surface);
    color: var(--color-neutral-text);

    .mq-accordion-toggle {
      cursor: not-allowed;
    }
  }
}