.mq-layout-section {
  width: 100%;
  display: flex;
  flex-direction: column;

  &.header {
    gap: var(--gl-spacing-scale-5);
  }

  &.page {
    gap: var(--gl-spacing-scale-5);
  }

  &.content {
    gap: var(--gl-spacing-scale-5);
  }

  &.footer {
    gap: var(--gl-spacing-scale-5);
  }
}