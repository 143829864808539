.mq-container {
  width: 100%;
  min-width: 350px;
  flex-basis: 100%;
  padding: 0;
  margin-left: auto;
  margin-right: auto;

  &.lg {
    max-width: 1400px;
  }

  &.md {
    max-width: 1140px;
  }

  &.sm {
    max-width: 800px;
  }
}