.mq-form-range__container {
  width: 100%;
  display: flex;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-2) 0;
  flex-direction: column;

  .mq-form-range {
    &[type="range"] {
      -webkit-appearance: none;
      border: initial;
      border-radius: var(--mq-border-radius-xs);
      background-color: var(--color-neutral-text-dim);
      height: 0.25rem;
    }

    &[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: var(--mq-border-radius-circle);
      background-color: var(--color-brand-indigo);
      cursor: pointer;
    }

    &__range-label {
      font-size: 0.75rem;
    }
  }

  .mq-form-range__labels-container {
    display: flex;
    justify-content: space-between;

    .mq-form-range__label {
      font-size: 0.75rem;
      line-height: var(--mq-line-height-compact);
    }
  }
}
