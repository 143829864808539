.mq-navbar {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .mq-link {
    color: var(--top-navigation-link-text);
    padding: var(--gl-spacing-scale-3);

    &:hover {
      color: var(--color-brand-blue);
    }
  }

  &.navbar {
    flex-wrap: nowrap;
    padding: 0;
    justify-content: flex-end;
    align-items: unset;
    gap: var(--gl-spacing-scale-6);
  }

  &.sticky-top {
    position: sticky;
    top: 0;
  }

  &.fixed-top {
    position: fixed;
    top: 0;
  }
}