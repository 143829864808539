.mq-form-select {
  width: 100%;
  height: max-content;
  min-height: 2.4rem;
  padding: var(--gl-spacing-scale-3);
  border-radius: var(--mq-border-radius-sm);
  border: 1px solid var(--color-neutral-stroke);
  font-size: var(--mq-font-size-scale-3);
  font-family: inherit;
  z-index: 0;

  &:focus:not(:disabled), &:active:not(:disabled) {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--color-brand-indigo);
  }

  &.is-invalid {
    border-color: var(--color-info-danger);
  }

  &.is-valid {
    border-color: var(--color-info-success);
  }

  &:disabled {
    background-color: var(--color-info-disabled);
    cursor: not-allowed;
  }

  &.countable {
    height: auto;
  }

  &.compact {
    width: initial;
  }
}