.mq-table {
  width: 100%;
  margin: 0;

  &.responsive {
    overflow: auto;
  }

  &.stretched {
    table {
      thead {
        th {
          width: 50%;
        }
      }
    }
  }

  &.borderless {
    table {
      tbody {
        tr {
          &:not(:last-child) {
            th,
            td {
              border-bottom: 1px solid var(--color-neutral-stroke);
            }
          }
        }
      }
    }
  }

  &:not(.borderless) {
    table {
      thead {
        tr {
          th,
          td {
            border-left: 1px solid var(--color-neutral-stroke);
            border-right: 1px solid var(--color-neutral-stroke);
          }
        }
      }

      tbody {
        tr {
          th,
          td {
            border: 1px solid var(--color-neutral-stroke);
          }
        }
      }
    }
  }

  &.inline {
    table {
      tbody {
        th,
        td {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.striped {
    tbody {
      tr {
        &:nth-child(even) {
          background: var(--color-neutral-bkg);
        }
      }
    }
  }

  &.centered {
    tbody {
      td {
        text-align: center;
      }
    }
  }

  &.horizontal-right {
    tbody {
      th,
      td {
        &:first-child {
          text-align: right;
        }
      }
    }
  }

  &.horizontal-left {
    tbody {
      th,
      td {
        &:first-child {
          text-align: left;
        }
      }
    }
  }

  &.align-top {
    table {
      vertical-align: top;
    }
  }

  &.align-middle {
    table,
    td {
      vertical-align: middle;
    }
  }

  &.align-bottom {
    table {
      vertical-align: bottom;
    }
  }

  &.sections-highlighted {
    table {
      tbody {
        th {
          background-color: var(--color-brand-blue-light);
        }
      }
    }
  }

  table {
    min-width: 100%;
    margin: 0;
    border-collapse: collapse;
    caption-side: bottom;

    caption {
      text-align: left;
      margin: var(--gl-spacing-scale-3) 0;
    }

    thead {
      th {
        font-size: var(--mq-font-size-scale-2);
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
        padding: var(--gl-spacing-scale-3);
        border-top: 1px solid var(--color-neutral-stroke);
        border-bottom: 2px solid var(--color-brand-indigo);

        &.header-start {
          text-align: start
        }

        &.header-center {
          text-align: center
        }

        &.header-end {
          text-align: end
        }
      }
    }

    tfoot,
    tbody {
      vertical-align: inherit;

      tr {
        td {
          padding: var(--gl-spacing-scale-3);
          font-size: var(--mq-font-size-scale-2);
        }

        th {
          font-size: var(--mq-font-size-scale-2);
          font-weight: bold;
          padding: var(--gl-spacing-scale-3);

          &.header-start {
            text-align: start
          }

          &.header-center {
            text-align: center
          }

          &.header-end {
            text-align: end
          }
        }
      }
    }

  }

  &.secondary {
    tbody {
      tr {
        &:nth-child(even) {
          background: none;
        }

        &:nth-child(2n+1) {
          background: var(--color-neutral-bkg);
        }
      }
    }

    tbody:before {
      content: "-";
      display: block;
      line-height: 0;
      color: transparent;
    }

    table {
      tbody {
        tr {
          &:not(:last-child) {
            th,
            td {
              padding: var(--gl-spacing-scale-2) var(--gl-spacing-scale-3);
              border: none;
            }
          }
        }
      }
    }
  }
}
