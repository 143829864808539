.app-chat__rooms {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: inherit;

  .app-chat__rooms-header {
    width: 100%;
    height: 4rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
    padding: var(--gl-spacing-scale-3);
    background-color: var(--color-neutral-bkg);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom: 1px solid var(--color-neutral-stroke);
  }

  .app-chat__rooms-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--gl-spacing-scale-3);
    gap: var(--gl-spacing-scale-3);

    .app-chat__rooms-filters {
      width: 100%;
      flex-shrink: 0;
    }

    .app-chat__rooms-items {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;

      .app-chat__rooms-empty {
        width: 100%;
        text-align: center;
      }
    }
  }
}