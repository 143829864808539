.mq-virtualized {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0.5rem 0 0;
  margin: 0 -0.5rem 0 0;

  &.inset {
    box-shadow: var(--mq-shadow-inset);
  }

  .mq-virtualized__before,
  .mq-virtualized__after {
    width: 100%;
    flex-shrink: 0;
  }
}