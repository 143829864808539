.chat-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: flex-start;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-3);

  .chat-message__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: var(--gradient-marketing-cool);
    background-size: 200%;
    background-position: -100%;
    width: 45px;
    height: 45px;
    color: var(--color-neutral-text-light);
    border-radius: 100%;
    flex-shrink: 0;
    font-weight: bold;
  }


  img {
    height: auto;
    max-width: 100%;
  }

  .chat-message__ai-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    overflow-wrap: break-word;

    iframe {
      width: 100%;
    }
  }

  .chat-message__user-content {
    max-width: 70%;
    display: flex;
    margin-left: auto;
    flex-direction: column;
    background-color: var(--color-brand-blue);
    color: var(--color-neutral-text-light);
    padding: var(--gl-spacing-scale-3) var(--gl-spacing-scale-5);
    border-radius: var(--mq-border-radius-lg);
    word-break: break-word;
  }
}