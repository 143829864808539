.mq-avatar-group {
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--gl-spacing-scale-3);

  .mq-avatar-group__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .mq-avatar-group__item {
      display: flex;
      flex-direction: column;
    }

    &.overlap {
      .mq-avatar-group__item {
        &:not(:last-child) {
          margin-right: -15px;
        }
      }
    }

    &.nowrap {
      flex-wrap: nowrap;
    }

    &:not(.overlap) {
      gap: var(--gl-spacing-scale-3);
    }
  }
}
