.mq-avatar-info {
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-weight: bold;

  &:not(.borderless) {
    border: 1px solid currentColor;
  }

  &.primary {
    color: var(--color-brand-indigo);
    background-color: var(--color-brand-indigo-light);
  }

  &.secondary {
    color: var(--color-brand-indigo);
    background-color: var(--color-neutral-stroke);
  }


  &.warning {
    color: var(--color-info-warning);
    background-color: var(--color-info-warning-light);
  }

  &.success {
    color: var(--color-info-success);
    background-color: var(--color-info-success-light);
  }

  &.danger {
    color: var(--color-info-danger);
    background-color: var(--color-info-danger-light);
  }

  &.disabled {
    border: initial;
    background-color: var(--color-neutral-bkg);
  }

  &.lg {
    font-size: var(--mq-font-size-scale-9);
  }

  &.md {
    font-size: var(--mq-font-size-scale-8);
  }

  &.sm {
    font-size: var(--mq-font-size-scale-5);
  }

  &.xs {
    font-size: var(--mq-font-size-scale-2);
  }
}
