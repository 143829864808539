.mq-form-date-input {
  width: 100%;
  height: max-content;
  min-height: 2.4rem;
  max-height: 2.5rem;
  font-size: var(--mq-font-size-scale-3);
  border-radius: var(--mq-border-radius-sm);
  padding: 0 var(--gl-spacing-scale-3);
  border: 1px solid var(--color-neutral-stroke);
  font-family: inherit;
  color: var(--color-neutral-text);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;

  &:focus {
    border: 1px solid var(--color-brand-indigo);
  }

  &.is-valid {
    border-color: var(--color-info-success);
  }

  &.is-invalid {
    border-color: var(--color-info-danger);
  }

  &.stroke {
    border-radius: 0;
    border-right: none;
    border-left: none;
    border-top: none;

    &:focus {
      border-bottom: 1px solid var(--color-brand-indigo);
    }
  }

  &:disabled {
    background-color: var(--color-info-disabled);
    cursor: not-allowed;
  }

  &.compact {
    width: initial;
  }
}