.mq-card {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-wrap: break-word;
  border-radius: var(--mq-border-radius-sm);
  border: 1px solid var(--color-neutral-stroke);
  background-color: var(--color-neutral-surface);
  background-clip: border-box;
  box-shadow: var(--mq-shadow-sm);

  &.fill {
    height: 100%;
  }

  &:not(.stateless) {
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
      border: 1px solid var(--color-brand-indigo);
      color: var(--color-brand-indigo);
    }
  }

  &.light {
    border-color: var(--color-neutral-bkg);

    .mq-card-body {
      background-color: transparent;
    }

    &:not(.stateless) {
      &:hover {
        transform: scale(1.025);
        border: 1px solid var(--color-brand-blue);
      }
    }
  }

  &.transparent {
    border: 0;
    box-shadow: none;

    .mq-card-body {
      background-color: transparent;
    }
  }

  &.cover {
    background-color: rgba(var(--color-brand-indigo-rgb), 70%);
    padding: var(--gl-spacing-scale-10) var(--gl-spacing-scale-5) 0;

    .mq-card-body {
      border-radius: var(--mq-border-radius-sm) var(--mq-border-radius-sm) 0 0;
    }
  }

  &.alt {
    border: 1px solid var(--color-brand-blue);

    .mq-card-header {
      background-color: var(--color-brand-blue-light);
    }
  }

  &.gray {
    background-color: var(--color-neutral-bkg);
    padding: 0;

    .mq-card-body {
      background-color: var(--color-neutral-bkg);
      border-radius: 0 0 var(--mq-border-radius-sm) var(--mq-border-radius-sm);
    }

    &:not(.stateless) {
      &:hover {
        transform: scale(1.07);
        border-color: var(--color-neutral-bkg);

        .mq-card-header,
        .mq-card-body {
          background-color: var(--color-neutral-text-dim);
          color: var(--color-neutral-text);
        }
      }
    }
  }

  &.gray-transparent {
    background-color: var(--color-neutral-text-dim-transparent);
    padding: 0;

    .mq-card-body {
      background-color: var(--color-neutral-text-dim-transparent);
      border-radius: 0 0 var(--mq-border-radius-sm) var(--mq-border-radius-sm);
    }

    &:not(.stateless) {
      &:hover {
        transform: scale(1.07);
        border-color: var(--color-neutral-bkg);

        .mq-card-header,
        .mq-card-body,
        .mq-card__footer {
          background-color: var(--color-neutral-text-dim);
          color: var(--color-neutral-text);
        }
      }
    }
  }

  &.blue {
    border-radius: var(--mq-border-radius-md);

    &:hover {
      transform: scale(1.1);
      border-color: var(--color-info-blue);

      .mq-card-header,
      .mq-card-body {
      }
    }
  }

  &:has(.mq-card-badge) {
    margin-top: var(--gl-spacing-scale-6);
  }
}