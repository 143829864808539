.mq-layout-heading {
  width: 100%;
  margin: 0;
  color: var(--color-brand-indigo);
  font-weight: 400;
  height: fit-content;

  &.indigo {
    color: var(--color-brand-indigo);
  }

  &.purple {
    color: var(--color-marketing-purple);
  }

  &.purple-med {
    color: var(--color-marketing-purple-med);
  }

  &.neutral {
    color: var(--color-neutral-text);
  }

  &.neutral-med {
    color: var(--color-neutral-text-med);
  }

  &.neutral-light {
    color: var(--color-neutral-text-light);
  }

  &.blue {
    color: var(--color-brand-blue);
  }

  &.compact {
    width: max-content;
  }

  &.bold {
    font-weight: 700;
  }

  &.italic {
    font-style: italic;
  }

  &.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &__content {
      .mq-layout-heading__title {
        flex-wrap: nowrap;
      }
    }
  }

  &.center {
    .mq-layout-heading__title {
      justify-content: center;
      text-align: center;
    }


    .mq-layout-heading__content {
      justify-content: center;
    }
  }

  &__content {
    color: currentColor;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--gl-spacing-scale-3);

    .mq-layout-heading__title {
      color: currentColor;
      max-width: 100%;
      gap: var(--gl-spacing-scale-3);
    }

    .mq-layout-heading__info {
      font-size: var(--mq-font-size-scale-2);
      color: var(--color-neutral-text-med);
      display: contents;
    }
  }

  &__title {
    display: inline-flex;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
  }

  .mq-layout-heading__divider {
    color: inherit;
    height: 0.05em;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mq-layout-heading__description {
    font-size: var(--mq-font-size-scale-2);
    color: var(--color-neutral-text-med);
    display: contents;
  }
}
