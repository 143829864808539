.mq-form-color {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  height: max-content;
  min-height: 2.4rem;
  max-height: 2.5rem;

  .mq-form-color__swatch {
    width: 2.5rem;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    padding: 0;
    flex-shrink: 0;
    overflow: hidden;
    border: 1px solid var(--color-neutral-stroke);
    border-top-left-radius: var(--mq-border-radius-sm);
    border-bottom-left-radius: var(--mq-border-radius-sm);

    &::-webkit-color-swatch-wrapper {
      width: 100%;
      min-width: inherit;
      height: inherit;
      min-height: inherit;
      padding: 0;
      border: 0;
    }

    &::-webkit-color-swatch {
      border: 0;
    }
  }

  .mq-form-color__input {
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    padding: var(--gl-spacing-scale-3);
    font-size: 1em;
    color: var(--color-neutral-text);
    border: 1px solid var(--color-neutral-stroke);
    border-top-right-radius: var(--mq-border-radius-sm);
    border-bottom-right-radius: var(--mq-border-radius-sm);

    &:focus {
      border: 1px solid var(--color-brand-indigo);
    }

    &.is-valid {
      border-color: var(--color-info-success);
    }

    &.is-invalid {
      border-color: var(--color-info-danger);
    }
  }
}