.mq-form-feedback {
  font-size: var(--mq-font-size-scale-2);
  min-height: 0.875rem;
  width: 100%;

  &.valid {
    color: var(--color-info-success);
  }

  &.invalid {
    color: var(--color-info-danger);
  }
}