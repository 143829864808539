.mq-card__overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: inherit;

  &:not(.compact) {
    padding: var(--gl-spacing-scale-5);
  }
}