.mq-card__subtitle {
  width: 100%;
  font-style: italic;
  color: var(--color-neutral-text-med);

  &.center {
    text-align: center;
  }

  &.start {
    text-align: start;
  }

  &.end {
    text-align: end;
  }
}