@import 'assets/styles/mixins';

.cookies-banner {
  position: fixed;
  z-index: 4;
  bottom: 10px;
  left: 10px;

  .cookies-banner__container {
    width: 1000px;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: var(--color-neutral-surface);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: var(--gl-spacing-scale-5);
    white-space: pre-wrap;
    border: 1px solid var(--color-neutral-text);
    border-left: 7px solid var(--color-brand-indigo);
    box-shadow: var(--mq-shadow-md);
    border-bottom-right-radius: var(--mq-border-radius-md);
    border-top-right-radius: var(--mq-border-radius-md);
    bottom: 10px;
    left: 10px;

    @include for-desktop-down {
      width: 90%;
    }

    .cookies-banner__overlay {
      height: 100%;
      overflow: hidden;
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;

      svg {
        display: inline-block;
        font-size: 10rem;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: rotate(-25deg) translateY(-50%);
      }
    }
  }
}