.mq-form-multiselect {
  width: fit-content;
  display: flex;
  background-color: var(--color-neutral-text-light);
  flex-direction: column;
  gap: var(--gl-spacing-scale-3);
  position: relative;
  border-radius: var(--mq-border-radius-sm);

  &:not(.compact) {
    width: 100%;
  }

  &.is-valid {
    .mq-form-multiselect__toggle {
      border-color: var(--color-info-success);
    }
  }

  &.is-invalid {
    .mq-form-multiselect__toggle {
      border-color: var(--color-info-danger);
    }
  }

  &:disabled {
    .mq-form-multiselect__toggle {
      background-color: var(--color-info-disabled-dim);
      cursor: not-allowed;
    }
  }

  .mq-form-multiselect__toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: max-content;
    min-height: 2.4rem;
    font-size: var(--mq-font-size-scale-3);
    border-radius: var(--mq-border-radius-sm);
    border: 1px solid var(--color-neutral-stroke);
    font-family: inherit;
    color: var(--color-neutral-text);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:has(.mq-form-multiselect__editable:focus) {
      outline: none;
      box-shadow: none;
      border: 1px solid var(--color-brand-indigo);
    }

    .mq-form-multiselect__values {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      height: 100%;
      gap: var(--gl-spacing-scale-2);
      overflow: hidden;
      padding: var(--gl-spacing-scale-2) var(--gl-spacing-scale-3);

      .mq-form-multiselect__editable {
        font-size: var(--mq-font-size-scale-3);
        font-family: inherit;
        width: max-content;
        min-width: 150px;
        display: flex;
        flex: 1;
        border: 0;
        box-shadow: none;
        outline: none;
      }
    }

    .mq-form-multiselect__toggle-actions {
      display: flex;
      flex-shrink: 0;
      align-items: center;

      .mq-form-multiselect__toggle-loading {
        width: 2rem;
      }

      .mq-form-multiselect__toggle-button {
        width: 2rem;
        height: 100%;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1/1;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:not(:last-child) {
          border-right: 1px solid var(--color-neutral-stroke);
        }
      }
    }
  }
}

.mq-form-multiselect__options {
  max-height: 300px;
  overflow-y: auto;
  font-size: var(--mq-font-size-scale-2);
  width: max-content;
  margin: var(--gl-spacing-scale-3) 0;
  background-color: var(--color-neutral-surface);
  border-radius: var(--mq-border-radius-sm);
  border: 1px solid var(--color-neutral-stroke);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  box-shadow: var(--mq-shadow-md);

  .mq-form-multiselect__empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--gl-spacing-scale-3);
  }

  .mq-form-multiselect__option {
    width: 100%;
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    padding: var(--gl-spacing-scale-3);
    cursor: pointer;

    &.selected,
    &:active {
      background: rgba(var(--color-info-blue-rgb), 0.3);
    }

    &:hover {
      &:not(.selected) {
        background: var(--color-neutral-bkg);
      }
    }
  }

  .mq-form-multiselect__empty {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--color-neutral-text);
  }
}