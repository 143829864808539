.mq-form-group {
  width: 100%;
  position: relative;
  display: inline-flex;
  gap: var(--gl-spacing-scale-3);

  &.fill {
    height: 100%;
  }

  &.align-start {
    align-items: start;
  }

  &.align-end {
    align-items: end;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-start {
    justify-content: start;
  }

  &.justify-end {
    justify-content: end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.row {
    flex-direction: row;
  }

  &.row-reverse {
    flex-direction: row-reverse;
  }

  &.column {
    flex-direction: column;
  }

  &.column-reverse {
    flex-direction: column-reverse;
  }
}
