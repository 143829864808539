.mq-sidebar__item {
  display: flex;
  width: 100%;

  img {
    width: 50px;
    height: 50px;
    border-radius: 0.5rem;
    margin-right: 1rem;
    object-fit: cover;
  }

  .mq-sidebar__link {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
    padding: 0.75em;
    font-size: var(--mq-font-size-scale-2);
    color: var(--color-neutral-text);
    line-height: var(--mq-line-height-sm);

    &.active {
      font-weight: bold;
      color: var(--color-brand-indigo);
      background-color: var(--color-neutral-surface);
    }

    .mq-sidebar__item-icon {
      flex-shrink: 0;
    }

    .mq-sidebar__item-label {
      width: 100%;
    }

    .mq-sidebar__item-overlay {
      opacity: 0;
      color: var(--admin-panel-arrow-icon);
      z-index: 1;
    }

    &:hover {
      .mq-sidebar__item-overlay {
        opacity: 1;
      }
    }
  }

  &.truncate {
    .mq-sidebar__item-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}