.app-chat-toolbar {
  width: 100%;
  height: 100%;

  .app-chat-toolbar__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);
    padding: var(--gl-spacing-scale-3);

    .app-chat-toolbar__attachment {
      width: fit-content;
      max-width: 200px;
      border-radius: var(--mq-border-radius-sm);
      background-color: var(--color-neutral-surface);
      border: 1px solid var(--color-neutral-stroke);

      &:has(audio) {
        max-width: 300px;
      }
    }

    .app-chat-toolbar__buttons {
      width: 100%;
      display: flex;
      gap: var(--gl-spacing-scale-3);
      background-color: var(--color-neutral-bkg);

      .app-chat-toolbar__tools {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .app-chat-toolbar__helpers {
          display: flex;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .app-chat-toolbar__input {
          width: 100%;
          max-width: 100%;
          max-height: 300px;
          padding-right: 70px;
          overflow: hidden;
        }
      }
    }

    .app-chat-toolbar__emojis {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
    }
  }
}