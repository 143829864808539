.mq-alert {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: var(--gl-spacing-scale-5);
  z-index: 1;
  white-space: pre-wrap;

  .mq-alert__icon {
    margin-right: 0.5em;
  }

  .mq-alert__help {
    font-size: var(--mq-font-size-scale-3);
    font-style: italic;
    font-weight: normal;
    color: var(--color-neutral-text-dim);
    display: block;
    margin-top: 1rem;
  }

  .mq-alert__container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);
    color: currentColor;
    margin-right: var(--gl-spacing-scale-9);
    z-index: 2;

    .mq-alert__heading {
      font-weight: bold;
      color: currentColor;
      margin: 0 var(--gl-spacing-scale-3) 0 0;
      word-wrap: break-word;
    }

    .mq-alert__button {
      margin-top: var(--gl-spacing-scale-5);
    }
  }

  .mq-alert__overlay {
    opacity: 0.2;

    svg {
      font-size: 8rem;
    }
  }

  &:not(.new){
    .mq-alert__overlay {
      height: 100%;
      overflow: hidden;
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;

      svg {
        display: inline-block;
        font-size: 8rem;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: rotate(25deg) translateY(-50%);

        &.fa-flip-horizontal {
          transform: scaleX(-1) rotate(-25deg);
        }
      }
    }
  }

  &.primary {
    border: 1px solid var(--color-brand-indigo);
    border-left: 7px solid var(--color-brand-indigo);
    border-radius: 0 8px 8px 0;
    background-image: transparent linear-gradient(90deg, var(--color-brand-indigo-light) 0%, #fff 100%) 0 0;
    color: var(--color-brand-indigo);

    .mq-alert__overlay {
      svg path {
        fill: var(--color-brand-indigo);
      }
    }
  }

  &.light {
    border: 1px solid var(--color-brand-indigo-light);
    border-left: 7px solid var(--color-brand-indigo-light);
    border-radius: 0 8px 8px 0;
    background-image: transparent linear-gradient(90deg, var(--color-brand-indigo-light) 0%, #fff 100%) 0 0;
    color: var(--color-brand-indigo);

    .mq-alert__overlay {
      svg path {
        fill: var(--color-brand-indigo-light);
      }
    }
  }

  &.dark {
    border: 1px solid var(--color-brand-indigo-dark);
    border-left: 7px solid var(--color-brand-indigo-dark);
    border-radius: 0 0.5em 0.5em 0;
    background-image: transparent linear-gradient(90deg, var(--color-brand-indigo-dark) 0%, #fff 100%) 0 0;
    color: var(--color-neutral-surface);

    .mq-alert__overlay {
      svg path {
        fill: var(--color-brand-dark);
      }
    }
  }

  &.success {
    border: 1px solid var(--color-info-success);
    border-left: 7px solid var(--color-info-success);
    border-radius: 0 8px 8px 0;
    background-image: linear-gradient(90deg, var(--color-info-success-transparent) 0%, var(--color-neutral-surface) 100%);
    color: var(--color-info-success-text);

    .mq-alert__overlay {
      svg path {
        fill: var(--color-info-success);
      }
    }
  }

  &.warning {
    border: 1px solid var(--color-info-warning);
    border-left: 7px solid var(--color-info-warning);
    border-radius: 0 8px 8px 0;
    background-image: linear-gradient(90deg, var(--color-info-warning-light) 0%, var(--color-neutral-surface) 100%);
    color: var(--color-info-warning-text);

    .mq-alert__overlay {
      svg path {
        fill: var(--color-info-warning);
      }
    }
  }

  &.danger {
    border: 1px solid var(--color-info-danger);
    border-left: 7px solid var(--color-info-danger);
    border-radius: 0 8px 8px 0;
    background-image: linear-gradient(90deg, var(--color-info-danger-light) 0%, var(--color-neutral-surface) 100%);
    color: var(--color-info-danger-text);

    .mq-alert__overlay {
      svg path {
        fill: var(--color-info-danger);
      }
    }
  }

  &.info {
    color: var(--color-brand-blue);
    border: 1px solid var(--color-brand-blue);
    border-left: 7px solid var(--color-brand-blue);
    background-image: linear-gradient(90deg, var(--color-brand-blue-light) 0%, var(--color-neutral-surface) 100%);
    border-radius: 0 8px 8px 0;

    .mq-alert__overlay {
      svg path {
        fill: var(--color-brand-blue);
      }
    }
  }

  &.new {
    color: var(--color-brand-indigo);
    border: 1px solid var(--color-brand-indigo);
    border-left: 7px solid var(--color-brand-indigo);
    background-image: linear-gradient(90deg, var(--color-neutral-bkg) 0%, var(--color-neutral-surface) 100%);
    border-radius: 0 8px 8px 0;

    .mq-alert__overlay {
      all: initial;
      opacity: 1;

      svg {
        font-size: 8rem;
      }
    }
  }

  .mq-alert__close {
    top: 0;
    bottom: 0;
    opacity: 0.6;
    text-shadow: none;
    color: currentColor;
    cursor: pointer;

    &:not(:disabled) {
      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }
}
