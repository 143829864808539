.mq-form-rating {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  overflow: hidden;
  gap: var(--gl-spacing-scale-3);

  &__filled {
    display: flex;
    align-items: center;
    font-size: var(--mq-font-size-scale-8);

    .mq-form-rating__star {
      border: none;
      background: transparent;
      font-size: inherit;
      cursor: pointer;
      padding: initial;

      &.star-empty {
        svg {
          path {
            fill: var(--color-neutral-text-med);
          }
        }
      }

      &.star-full {
        svg {
          path {
            fill: var(--color-marketing-gold);
          }
        }
      }

      &:disabled {
        svg {
          path {
            fill: var(--color-info-disabled-text);
          }
        }
        cursor: not-allowed;
      }
    }
  }

  .badge {
    position: relative;
    color: var(--color-neutral-surface);
  }
}