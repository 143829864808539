.mq-grid-row {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  &.centered {
    justify-content: center;
  }

  &.compact {
    margin-right: 0;
    margin-left: 0;
  }
}