.mq-card-link {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-2) var(--gl-spacing-scale-3);
  color: var(--color-brand-indigo);
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: var(--color-brand-blue);
  }

  .mq-card-link__icon {
    flex-shrink: 0;
  }

  .mq-card-link__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}