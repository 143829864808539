.mq-form-field {
  width: 100%;
  display: flex;
  position: relative;

  .mq-form-field__input {
    width: 100%;
    flex: 1;
    margin: -1px 0;
  }

  .mq-form-field__append {
    height: 100%;
    position: absolute;
    aspect-ratio: 1/1;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mq-form-field__prepend {
    height: 100%;
    position: absolute;
    aspect-ratio: 1/1;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}