.mq-sidebar {
  height: 100%;

  &.sticky {
    position: sticky;
  }

  &.fixed {
    position: fixed;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}
