.mq-grid-flex-box {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  &.compact {
    width: fit-content;
    flex-shrink: 0;
  }

  &.sm {
    gap: var(--gl-spacing-scale-3);
  }

  &.md {
    &.padding {
      padding: var(--gl-spacing-scale-3);
    }

    gap: var(--gl-spacing-scale-5);
  }

  &.lg {
    &.padding {
      padding: var(--gl-spacing-scale-9);
    }

    gap: var(--gl-spacing-scale-9);
  }

  &.no-space {
    gap: 0;
  }

  &.fill {
    height: 100%;
    width: 100%;
  }

  &__direction-row {
    flex-direction: row;
  }

  &__direction-column {
    flex-direction: column;
  }

  &__justify-center {
    justify-content: center;
  }

  &__justify-start {
    justify-content: flex-start;
    text-align: left;
  }

  &__justify-end {
    justify-content: flex-end;
    text-align: right;
  }

  &__justify-space-between {
    justify-content: space-between;
  }

  &__justify-space-around {
    justify-content: space-around;
  }

  &__align-center {
    align-items: center;
  }

  &__align-start {
    align-items: flex-start;
  }

  &__align-end {
    align-items: flex-end;
  }

  &__align-stretch {
    align-items: stretch;
  }

  &__flex-wrap {
    flex-wrap: wrap;
  }
}
