.app-chat-room {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-3);

  .app-chat-room__icon {
    flex-shrink: 0;
  }

  .app-chat-room__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.unread {
      font-weight: bold;
    }

    .app-chat-room__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .app-chat-room__members {
        display: flex;
        max-width: 60%;
      }

      .app-chat-room__date {
        flex-shrink: 0;
        color: var(--color-neutral-text-med);
      }
    }


    .app-chat-room__message {
      width: 100%;
      display: flex;
      align-items: center;

      .app-chat-room__message-text {
        width: 100%;
        font-size: var(--mq-font-size-scale-2);
        color: var(--color-neutral-text-med);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .app-chat-room__unread {
        flex-shrink: 0;
      }
    }
  }
}