html,
body {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  font-family: Lato, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;

  #root {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }
}
