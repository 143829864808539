.mq-avatar-badge {
  width: fit-content;
  display: flex;
  flex-shrink: 0;
  position: relative;

  &:not(.inline) {
    flex-direction: column;
    align-items: center;

    .mq-avatar-badge__label {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 var(--gl-spacing-scale-3);
    }

    .mq-avatar-badge__image {
      display: flex;
    }
  }

  &.inline {
    flex-direction: row;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
  }
}
