.mq-card__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: var(--gl-spacing-scale-3);
  gap: var(--gl-spacing-scale-3);
  align-items: center;

  &.alt {
    border-top: 1px solid var(--color-neutral-stroke);
    background-color: var(--color-neutral-bkg);
  }

  &.space-between {
    justify-content: space-between;
  }

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }
}