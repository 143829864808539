.mq-card-badge {
  position: absolute;
  width: auto;
  max-width: 100%;
  top: 0;
  z-index: 1;

  &.sticky {
    position: relative;
    max-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gl-spacing-scale-3);
  }

  &.center {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.left {
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &.right {
    right: -1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}