@import 'assets/styles/mixins';

.app-header {
  width: 100%;
  min-height: inherit;

  .app-header-collapse-dropdown {
    padding: 0;
    .mq-nav-dropdown__menu {
      position: unset;
      box-shadow: unset;
    }
  }

  .app-header__section {
    width: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;

    .app-header__line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--gl-spacing-scale-3);
      padding: var(--gl-spacing-scale-3);
      background-color: var(--color-neutral-surface);
    }

    .app-header__navbar {
      width: 100%;
      min-height: inherit;
      background-color: var(--top-navigation-background);
      border-bottom: 1px solid var(--top-navigation-border);
      box-shadow: var(--mq-shadow-md);

      @include for-tablet-landscape-down {
        justify-content: space-between;
      }

      .app-header__navbar-collapse {
        .mq-dropdown__toggle>.mq-link {
          border: none;
        }
      }

      .app-header__navbar-menu {
        height: auto;
        min-height: 100%;
        .app-header__toggle {
          height: 100%;
          background-color: var(--top-navigation-background);

          border: 0;
          border-right: 1px solid var(--color-neutral-stroke);


        }
        .app-header__navbar-link {
          color: var(--top-navigation-link-text);

          &:hover {
            color: var(--top-navigation-link-text-hover);
          }
        }
      }

      .app-header__profile {
        width: fit-content;
        max-width: 250px;
        min-width: 60px;

        .app-header__profile__menu {
          width: 100%;
          min-width: 200px;

          .app-header__profile__menu-item {
            background-color: var(--user-menu-background);

            .app-header__profile__menu-item-icon {
              color: var(--user-menu-link-icon);
            }

            .app-header__profile__menu-item-title {
              color: var(--user-menu-link-text);
            }

            &:hover {
              .app-header__profile__menu-item-icon {
                color: var(--user-menu-link-icon-hover);
              }

              .app-header__profile__menu-item-title {
                color: var(--user-menu-link-text-hover);
              }
            }
          }
        }

        .app-header__profile-real-name {
          color: var(--top-navigation-link-text);
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            color: var(--top-navigation-link-text-hover);
          }
        }

        .app-header__profile__toggle {
          max-width: 100%;
          border: 0;
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
          border-top-left-radius: var(--mq-border-radius-xl);
          border-bottom-left-radius: var(--mq-border-radius-xl);
          transition: border-radius 0.2s ease-in-out;
          border-right: 1px solid var(--top-navigation-border);
          padding: var(--gl-spacing-scale-3);
          gap: var(--gl-spacing-scale-3);
        }

        .app-header__profile__name {
          color: var(--top-navigation-link-text);
          font-weight: bold;
        }

        &:hover {
          .app-header__profile__name {
            color: var(--top-navigation-link-text-hover);
          }
        }

        &.show {
          .app-header__profile__toggle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          .app-header__profile-real-name {
            color: var(--top-navigation-link-text-hover);
          }
        }
      }

      .app-header__menu-item {
        padding-left: var(--gl-spacing-scale-11);
      }

      .app-header__brand {
        .app-header__logo {
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            width: auto;
            height: 50px;
            margin: 0;
            object-fit: contain;
          }
        }
      }

      .app-header__language {
        .app-header__language-icon {
          color: var(--language-option-icon);
        }

        .app-header__language-text {
          text-transform: uppercase;
          color: var(--language-option-link);
        }

        &:hover {
          .app-header__language-icon {
            color: var(--language-option-icon-hover);
          }

          .app-header__language-text {
            color: var(--language-option-link-hover);
          }
        }
      }
    }
  }
}
