.mq-nav-dropdown__menu {
  width: fit-content;
  top: 100%;
  right: 0;
  box-shadow: var(--mq-shadow-sm);
  border: 1px solid rgb(0, 0, 0, 0.1);
  background: var(--color-neutral-text-light);
  border-bottom-left-radius: var(--mq-border-radius-sm);
  border-bottom-right-radius: var(--mq-border-radius-sm);
  display: none;
  position: absolute;
}