.mq-form-nav {
  width: 100%;
  padding: 0;
  list-style: none;
  border-radius: 0.5em;
  display: flex;

  &:not(.nowrap) {
    flex-wrap: wrap;

    .mq-form__nav-item {
      width: fit-content;
      max-width: 100%;
    }
  }


  &.nowrap {
    flex-wrap: nowrap;
  }

  &.alt {
    background-color: var(--color-brand-blue-light);
  }
}
