.mq-form-switch {
  display: flex;
  align-items: center;
  position: relative;

  .mq-form-switch__label {
    min-height: 1.5rem;
    padding-left: var(--gl-spacing-scale-11);
    font-size: var(--mq-font-size-scale-3);
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 2.5rem;
      height: 1.5rem;
      border: 1px solid var(--color-neutral-text-med);
      border-radius: var(--mq-border-radius-xl);
      background-color: var(--color-neutral-bkg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.25rem;
      display: block;
      width: 1rem;
      height: 1rem;
      border: 1px solid var(--color-neutral-text-med);
      border-radius: var(--mq-border-radius-circle);
      background: var(--color-neutral-surface);
    }
  }

  .mq-form-switch__input {
    position: absolute;
    left: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: all;

    &:checked {
      ~ .mq-form-switch__label {
        &:before {
          background-color: var(--color-brand-indigo);
          border-color: var(--color-brand-indigo);
        }

        &:after {
          transform: translate(1rem, -50%);
          border-color: var(--color-neutral-surface);
        }
      }
    }

    &.is-valid ~ .mq-form-switch__label {
      color: var(--color-info-success);
    }

    &.is-invalid ~ .mq-form-switch__label {
      color: var(--color-info-danger);

      &:before {
        border-color: var(--color-info-danger);
      }
    }

    &:focus ~ .mq-form-switch__label,
    &:active ~ .mq-form-switch__label, {
      &:before {
        border-color: var(--color-brand-blue);
      }

      &:after {
        border-color: var(--color-brand-blue);
      }
    }

    &:checked {
      &:focus ~ .mq-form-switch__label,
      &:active ~ .mq-form-switch__label, {
        &:before {
          border-color: var(--color-brand-blue);
          background-color: var(--color-brand-blue);
        }

        &:after {
          border-color: var(--color-neutral-surface);
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      ~ .mq-form-switch__label {
        &:before, &:after {
          opacity: 0.6;
        }
      }
    }

    &:disabled:checked {
      cursor: not-allowed;
      ~ .mq-form-switch__label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &.disabled {
    .mq-form-switch__label {
      cursor: not-allowed;
    }
  }

  &.checked {
    .mq-form-switch__label:not(:has(b, strong)){
      font-weight: bold;
    }
  }
}