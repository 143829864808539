.app-view-as {
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 0;
  bottom: 2rem;
  pointer-events: none;
  left: 0;
  right: 0;
  z-index: 2;

  .app-view-as__wrapper {
    pointer-events: all;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
    max-width: 90%;
    width: fit-content;
    padding: var(--gl-spacing-scale-3) var(--gl-spacing-scale-5);
    background-color: var(--color-brand-blue);
    border-radius: var(--mq-border-radius-sm);
    color: var(--color-neutral-text-light);
    box-shadow: var(--mq-shadow-md);
  }
}