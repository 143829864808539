.app-file-preview {
  width: 100%;
  max-height: 100%;
  display: flex;
  border-radius: inherit;
  position: relative;

  &:has(.app-file-preview__video),
  &:has(.app-file-preview__audio),
  &:has(.app-file-preview__image) {
    flex-direction: column;
  }

  &:has(.app-file-preview__file) {
    flex-direction: row;
  }

  .app-file-preview__image {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-shrink: 0;
    border: 0;
    background: transparent;
  }

  .app-file-preview__file {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-brand-indigo-light);
    color: var(--color-brand-indigo);
    border: 0;
    flex-shrink: 0;
  }

  .app-file-preview__video {
    width: 100%;
    flex-shrink: 0;
    display: flex;
  }

  .app-file-preview__audio {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    background-color: var(--color-brand-indigo-light);
    padding: var(--gl-spacing-scale-5) var(--gl-spacing-scale-3);
  }

  .app-file-preview__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
    padding: var(--gl-spacing-scale-3);
    border-radius: inherit;
    overflow: hidden;

    .app-file-preview__info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--gl-spacing-scale-2);
      overflow: hidden;
      cursor: default;
    }
  }

  .app-file-preview__cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gl-spacing-scale-3);
    font-size: var(--gl-font-size-scale-3);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: var(--color-brand-blue);
    background-color: var(--color-brand-indigo-light);
    border: 0;
    overflow: hidden;
    border-radius: inherit;
  }

  &:focus,
  &:hover {
    .app-file-preview__cover {
      opacity: 1;
    }
  }
}