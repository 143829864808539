.mq-accordion {
  width: 100%;

  &.bordered {
    .mq-accordion-item {
      border: 1px solid var(--color-neutral-stroke);

      .mq-accordion-toggle {
        &.collapsed {
          border-bottom: 1px solid var(--color-neutral-stroke);
        }
      }
    }
  }

  &.stripped {
    .mq-accordion-item {
      border-top: 1px solid var(--color-neutral-stroke);

      .mq-accordion-toggle {
        &.collapsed {
          border-bottom: 1px solid var(--color-neutral-stroke);
        }
      }
    }
  }
}
