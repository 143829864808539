.mq-form-input-group-text {
  display: flex;
  align-items: center;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-2) var(--gl-spacing-scale-5);
  color: var(--color-neutral-text);
  text-align: center;
  white-space: nowrap;
  background-color: var(--color-neutral-bkg);
  border: 1px solid var(--color-neutral-stroke);
}