.app-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .app-layout__header {
    width: 100%;
    min-height: 60px;
    flex-shrink: 0;
    z-index: 3;
  }

  .app-layout__content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .app-layout__sidebar {
      width: 250px;
      min-width: 200px;
      height: 100%;
      margin-left: -240px;
      transition: all 0.3s ease-in-out;
      box-shadow: var(--mq-shadow-md);
      flex-shrink: 0;
      z-index: 2;

      &.expanded {
        height: 100%;
        margin-left: 0;
      }
    }

    .app-layout__wrapper {
      width: 100%;
      height: 100%;
      min-height: 100%;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      gap: var(--gl-spacing-scale-3);

      .app-layout-banner {
        position: initial;
        z-index: 1;

        &.sticky {
          top: 0;
          right: 0;
          left: 0;
          position: sticky;
        }
      }

      .app-layout__container {
        width: 100%;
        min-height: 100%;
        padding: var(--gl-spacing-scale-9) var(--gl-spacing-scale-5);
        display: flex;
        flex-direction: column;

        .app-layout__body {
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: var(--gl-spacing-scale-5);
        }
      }
    }
  }
}