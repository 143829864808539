.mq-card-nav {
  width: auto;
  min-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .mq-card-link {
    &:not(:last-child) {
      border-right: 1px solid var(--color-neutral-stroke);
    }
  }
}