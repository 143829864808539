.app-chat__menu {
  width: 100%;
  margin: 0;
  padding: 0;

  .app-chat__menu-item {
    width: 100%;
    padding: 0;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral-stroke);
    }

    &.active,
    &:active {
      background-color: var(--color-brand-blue-light);
    }

    &:hover {
      background-color: var(--color-neutral-bkg);
    }
  }
}