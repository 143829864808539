$tab-min-width: 100px;
$tab-max-width: 250px;
$border-width: 1px;
$border-color: var(--color-neutral-surface);
$border-color-active: var(--color-brand-indigo);

.mq-nav,
.mq-tabs {
  height: 100%;

  &:not(.expanded) {
    gap: var(--gl-spacing-scale-6);
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
  }

  .mq-link {
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--top-navigation-link-text);
  }

  .mq-nav__link {
    &.nav-link {
      padding: 0.5em 1rem;
    }
  }

  &.nav-tabs {
    width: 100%;

    &:not(.borderless) {
      border-bottom: $border-width solid $border-color-active;

      .nav-link,
      .tab {
        border: $border-width solid $border-color;
      }
    }

    &.borderless {
      .nav-link,
      .tab {
        border: $border-width solid transparent;

        &.active {
          border-color: transparent;

          &:hover,
          &:focus {
            border-color: transparent;
          }
        }
      }
    }

    .nav-link,
    .tab {
      min-width: $tab-min-width;
      max-width: $tab-max-width;
      display: flex;
      justify-content: center;
      font-size: var(--mq-font-size-scale-2);
      align-items: center;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0 var(--gl-spacing-scale-3) 0 0;
      color: var(--color-brand-blue);
      background-color: transparent;
      border-radius: var(--mq-border-radius-sm)  var(--mq-border-radius-sm) 0 0;
      border-bottom-width: 0;

      &:hover,
      &:focus {
        background-color: var(--color-neutral-bkg);
        color: $border-color-active;
      }

      &.active {
        font-weight: 600;
        margin-bottom: (-$border-width);
        color: $border-color-active;
        border-color: $border-color-active;
        border-bottom: none;

        &:hover,
        &:focus {
          color: $border-color-active;
          border-color: $border-color-active;
          background-color: var(--color-neutral-surface);
        }
      }
    }

    .nav-card {
      max-width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      gap: var(--gl-spacing-scale-2);
      padding: var(--gl-spacing-scale-3);
      background-color: var(--color-neutral-bkg);
      border-color: var(--color-neutral-stroke);
      color: var(--color-neutral-text);
      border-radius: 0;
      margin: 0;

      &:hover,
      &:focus,
      &.active {
        color: var(--color-brand-indigo);

        .start-icon,
        .end-icon {
          svg {
            color: var(--color-brand-indigo);
          }
        }
      }

      &.active {
        background-color: var(--color-neutral-surface);
        border-color: var(--color-brand-indigo);
      }

      .start-icon,
      .end-icon {
        svg {
          width: 2rem;
          height: auto;
          color: var(--color-neutral-text-med);
        }
      }
    }
  }

  &.nav-pills {
    .nav-item {
      box-sizing: border-box;
      border: 1px solid var(--color-neutral-stroke);
      border-radius: 0;
      position: relative;
      margin-left: -1px;
      overflow: hidden;

      &:first-child {
        border-top-left-radius: var(--mq-border-radius-sm);
        border-bottom-left-radius: var(--mq-border-radius-sm);
      }

      &:last-child {
        border-top-right-radius: var(--mq-border-radius-sm);
        border-bottom-right-radius: var(--mq-border-radius-sm);
      }

      .nav-link {
        border-radius: 0;
        font-size: var(--mq-font-size-scale-3);
        font-weight: bold;
        padding: var(--gl-spacing-scale-3) var(--gl-spacing-scale-5);

        &.active {
          background-color: var(--color-brand-indigo);
          color: var(--color-neutral-text-light);
        }
      }
    }
  }

  &.navbar-nav {
    flex-direction: row;
    gap: var(--gl-spacing-scale-3);
  }
}