.app-data-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gl-spacing-scale-3);

  .app-data-list__body {
    width: 100%;
  }

  .app-data-list__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .app-data-list__empty {
    width: 100%;
    display: flex;
    align-items: center;
  }
}