.mq-form-label {
  width: max-content;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  &.inline {
    height: 100%;
    justify-content: center;
  }

  &.nowrap {
    .mq-form-label__title,
    .mq-form-label__description {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mq-form-label__title {
    width: 100%;
    font-size: var(--mq-font-size-scale-3);
    font-weight: 700;

    .mq-form-label__required-icon {
      margin-left: var(--gl-spacing-scale-2);
      color: var(--color-info-danger-text);
    }

    .mq-form-label__help-icon {
      margin-left: var(--gl-spacing-scale-3);
      color: var(--color-brand-indigo);
    }
  }

  .mq-form-label__description {
    font-size: var(--mq-font-size-scale-3);
    font-style: italic;
    color: var(--color-neutral-text-med);

    svg {
      margin-right: var(--gl-spacing-scale-3);
    }
  }
}
