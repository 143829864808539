.cliq-ai-loading-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: flex-start;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-3);

  .cliq-ai-loading-message__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: var(--gradient-marketing-cool);
    background-size: 200%;
    background-position: -100%;
    width: 45px;
    height: 45px;
    color: var(--color-neutral-text-light);
    border-radius: 100%;
    flex-shrink: 0;
    font-weight: bold;
  }
}