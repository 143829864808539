.mq-form-radio {
  position: relative;

  &:not(.inline) {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
  }

  &.inline {
    width: 100%;

    .mq-form-radio__label {
      width: 100%;
      display: flex;

      .mq-form-radio__title {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .mq-form-radio__label {
    position: relative;
    font-size: var(--mq-font-size-scale-3);
    cursor: pointer;
    padding-left: var(--gl-spacing-scale-7);

    &:before {
      content: '';
      position: absolute;
      top: 0.2rem;
      left: 0;
      height: 1rem;
      width: 1rem;
      border-radius: var(--mq-border-radius-circle);
      border: 2px solid var(--color-neutral-stroke);
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      display: block;
      pointer-events: none;
      background-color: var(--color-neutral-surface);
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0.6rem;
      height: 0.4rem;
      top: 0.4rem;
      left: 0.2rem;
      border: 3px solid var(--color-neutral-surface);
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:empty {
      padding-left: var(--gl-spacing-scale-5);
    }
  }

  .mq-form-radio__input {
    position: absolute;
    left: 0;
    z-index: -1;
    opacity: 0;
    height: 1rem;
    width: 1rem;
    pointer-events: all;
    margin: var(--gl-spacing-scale-2);

    &:not(:disabled) {
      &.is-valid ~ .mq-form-radio__label {
        color: var(--color-info-success);

        &:before {
          border-color: var(--color-info-success);
        }
      }

      &.is-invalid ~ .mq-form-radio__label {
        color: var(--color-info-danger);

        &:before {
          border-color: var(--color-info-danger);
        }
      }

      &:focus ~ .mq-form-radio__label,
      &:hover ~ .mq-form-radio__label, {
        &:after {
          color: var(--color-neutral-surface);
        }

        &:before {
          box-shadow: none;
          border-color: var(--color-brand-blue);
        }
      }

      &:active ~ .mq-form-radio__label {
        &:after {
          color: var(--color-neutral-surface);
        }

        &:before {
          box-shadow: none;
          border-color: var(--color-brand-blue);
          background-color: var(--color-neutral-surface);
        }
      }

      &:checked ~ .mq-form-radio__label {
        &:after {
          color: var(--color-neutral-surface);
          opacity: 1;
          background-image: none;
        }

        &:before {
          border-color: var(--color-brand-indigo);
          background-color: var(--color-brand-indigo);
        }
      }

      &:checked {
        &:active ~ .mq-form-radio__label,
        &:focus ~ .mq-form-radio__label,
        &:hover ~ .mq-form-radio__label, {
          &:after {
            color: var(--color-neutral-surface);
            opacity: 1;
            background-image: none;
          }

          &:before {
            border-color: var(--color-brand-blue);
            background-color: var(--color-brand-blue);
          }
        }
      }
    }

    &:disabled:checked {
      & ~ .mq-form-radio__label {
        cursor: not-allowed;

        &:before {
          background-color: var(--color-info-disabled);
          border-color: var(--color-info-disabled);
        }

        &:after {
          opacity: 1;
          box-shadow: none;
          background-image: none;
          background-color: var(--color-info-disabled);
        }
      }
    }

    &:disabled {
      & ~ .mq-form-radio__label {
        pointer-events: all;
        cursor: not-allowed;
        color: var(--color-info-disabled-text);

        &:before {
          box-shadow: none;
          border-color: var(--color-info-disabled);
          background-color: var(--color-info-disabled);
          pointer-events: all;
        }

        &:after {
          opacity: 0;
          background-image: none;
          pointer-events: all;
        }
      }
    }
  }

  &__required {
    font-size: 1.125rem;
    padding-left: var(--gl-spacing-scale-2);
    color: var(--color-info-danger-text);
    font-weight: 700;
  }

  &__description {
    width: 100%;
    color: var(--color-neutral-text-med);
  }
}