.app-chat-message {
  width: 100%;
  display: flex;
  align-items: self-start;
  justify-content: flex-start;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-3);

  &.unread {
    font-weight: bold;
  }

  .app-chat-message__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);

    .app-chat-message__header {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: var(--gl-spacing-scale-3);
    }

    .app-chat-message__attachment {
      max-width: 50%;
      width: fit-content;
      cursor: pointer;
      position: relative;
      border-radius: var(--mq-border-radius-sm);
      background-color: var(--color-neutral-surface);
      border: 1px solid var(--color-neutral-stroke);
      overflow: hidden;
    }

    .app-chat-message__text {
      max-width: 100%;
      word-break: break-word;
    }
  }
}