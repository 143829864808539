.mq-sidebar__list-toggle {
  width: 100%;
  cursor: pointer;

  &.active {
    .mq-sidebar__list-toggle-link {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &:not(.active) {
    .mq-sidebar__list-toggle-link {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

    .mq-sidebar__list-badge-container-badge {
      max-width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--gl-spacing-scale-2);
      line-height: var(--mq-line-height-lg);
      font-size: var(--mq-font-size-scale-1);
      font-weight: 500;
      border-radius: var(--mq-border-radius-sm);
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.2);

      &.draft {
        border-style: dashed;
        background-color: transparent;
      }
    }

    .mq-sidebar__list-toggle-icon {
      position: absolute;
      right: var(--gl-spacing-scale-5);
    }

    &:hover {
      &:not(.active) {
        color: var(--admin-panel-link-text-hover);
        background: var(--admin-panel-rollover);
      }
    }
}
