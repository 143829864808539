.mq-form-text-editor {
  width: 100%;
  min-height: 100px;
  border: 1px solid var(--color-neutral-stroke);
  border-radius: var(--mq-border-radius-sm);
  padding: var(--gl-spacing-scale-3);

  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--color-brand-indigo);
  }

  mark {
    &[data-type="invalid"] {
      background-color: transparent;
    }
  }

  &.valid {
    border-color: var(--color-info-success);
  }

  &.invalid {
    border-color: var(--color-info-danger);

    mark {
      &[data-type="invalid"] {
        color: var(--color-info-danger);
        text-decoration: underline;
        text-decoration-color: var(--color-info-danger);
      }
    }
  }

  &.mq-resize-none {
    resize: none;
  }

  &.mq-resize-vertical {
    resize: vertical;
    overflow: auto;
  }

  &.mq-resize-horizontal {
    resize: horizontal;
    overflow: auto;
  }

  &.disabled {
    background-color: var(--color-info-disabled-dim);
    cursor: not-allowed;
  }
}