.mq-form-textarea {
  min-height: 2.4rem;
  font-size: var(--mq-font-size-scale-3);
  border-radius: var(--mq-border-radius-sm);
  padding: var(--gl-spacing-scale-3);
  border: 1px solid var(--color-neutral-stroke);
  line-height: var(--mq-line-height-sm);
  font-family: inherit;
  width: 100%;
  color: var(--color-neutral-text);
  transition: all 0.15s ease-in-out;
  outline: none;
  position: relative;

  &:focus {
    box-shadow: none;
    border: 1px solid var(--color-brand-indigo);
  }

  &.is-valid {
    border-color: var(--color-info-success);
  }

  &.is-invalid {
    border-color: var(--color-info-danger);
  }

  &.resize-none {
    resize: none;
  }

  &.resize-vertical {
    resize: vertical;
  }

  &.resize-horizontal {
    resize: horizontal;
  }

  &:disabled {
    background-color: var(--color-info-disabled);
    cursor: not-allowed;
  }
}