.mq-form-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0.5em;
  flex-grow: 1;
  gap: var(--gl-spacing-scale-3);

  &:not(.nowrap) {
    flex-wrap: wrap;
  }

  &.align-start {
    justify-content: flex-start;
  }

  &.align-end {
    justify-content: flex-end;
  }

  &.align-center {
    justify-content: center;

  }

  &.align-stretch {
    justify-content: stretch;
  }
}