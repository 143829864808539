.sidebar-program__item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .sidebar-program__item-label {
    max-width: 92%;
  }

  .sidebar-program__item-icon {
    display: none;
    color: var(--admin-panel-arrow-icon);
    align-self: center;
  }

  &:hover {
    .sidebar-program__item-icon {
      display: inline-block;
    }
  }
}