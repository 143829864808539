.mq-navbar-brand {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0 var(--gl-spacing-scale-3);
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1;

  &.navbar-brand {
    padding: 0 0.5rem;
  }

  &.branded {
    max-width: 200px;
    height: auto;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--gl-spacing-scale-3);
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      background-color: var(--top-navigation-logo-box-background);
      border: 1px solid var(--top-navigation-logo-box-border);
      border-top: none;
      box-shadow: 0 0 var(--gl-spacing-scale-7) 0 rgba(0, 0, 0, 0.05);

      img {
        height: 60px;
      }
    }
  }
}