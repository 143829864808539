.mq-form-input-group {
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  border-radius: var(--mq-border-radius-sm);

  &.compact {
    width: initial;
  }

  &.top {
    > * {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: var(--mq-border-radius-sm);

        * {
          border-radius: inherit;
        }
      }

      &:last-child {
        border-top-right-radius: var(--mq-border-radius-sm);

        * {
          border-radius: inherit;
        }
      }
    }
  }

  &.bottom {
   > * {
      border-radius: 0;

      &:first-child {
        border-bottom-left-radius: var(--mq-border-radius-sm);

        * {
          border-radius: inherit;
        }
      }

      &:last-child {
        border-bottom-right-radius: var(--mq-border-radius-sm);

        * {
          border-radius: inherit;
        }
      }
    }
  }

  &.auto {
   > * {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: var(--mq-border-radius-sm);
        border-bottom-left-radius: var(--mq-border-radius-sm);

        * {
          border-radius: inherit;
        }
      }

      &:last-child {
        border-top-right-radius: var(--mq-border-radius-sm);
        border-bottom-right-radius: var(--mq-border-radius-sm);

        * {
          border-radius: inherit;
        }
      }
    }
  }

  & > *:not(.mq-form-input-group-prepend, .mq-form-input-group-append) {
    flex: 1 1 auto;
  }
}
