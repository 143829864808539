.mq-layout-divider {
  position: relative;
  color: var(--color-neutral-stroke);
  background-color: currentColor;
  border: 0;
  margin: var(--gl-spacing-scale-3) 0;

  &.bold {
    border-bottom: 2px solid currentColor;
  }

  &.horizontal {
    width: 100%;
    height: 0.05rem;
  }

  &.vertical {
    width: 0.05rem;
    height: 100%;
  }

  &.vertical-right {
    width: 0.05rem;
    top: 0;
    bottom: 0;
    position: absolute;
    right: 0;
  }

  &.vertical-left {
    width: 1px;
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
  }

  &.compact {
    margin: 0;
  }

  &.stroke {
    color: var(--color-neutral-stroke);
  }

  &.purple-med {
    color: var(--color-marketing-purple-med);
  }

  &.indigo {
    color: var(--color-brand-indigo-dark);
  }
}