.app-cliq-ai {
  position: fixed;
  bottom: 14px;
  right: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 4;

  .app-cliq-ai__button {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 46px;
    height: 46px;
    font-size: inherit;
    border-radius: 50%;
    background-image: var(--gradient-marketing-cool);
    background-size: 200%;
    background-position: -100%;
    color: var(--color-neutral-text-light);
    border: none;
    cursor: pointer;
    transition: .3s ease;
    transition-property: width, border-radius;

    .app-cliq-ai__button-label {
      display: none;
      white-space: nowrap;
      transition: opacity 0.3s ease;
    }
  }

  .app-cliq-ai__button:hover {
    &.initial {
      width: 120px;
      border-radius: var(--mq-border-radius-xl);
    }

    .app-cliq-ai__button-label {
      display: inline;
      opacity: 1;
      margin-left: 10px;
    }
  }
}

