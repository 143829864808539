html,
body {
  &:has(.mq-tour-wrapper) {
    pointer-events: none;

    * {
      pointer-events: none;
    }

    .mq-tour-modal-wrapper {
      z-index: 4;
      width: fit-content;
      max-width: 500px;
    }

    .mq-tour-modal {
      padding: var(--gl-spacing-scale-2);
      flex-direction: column;
      background: var(--color-neutral-surface);
      border-radius: var(--mq-border-radius-sm);
      pointer-events: all;

      &[data-placement="top"] {
        margin: var(--gl-spacing-scale-2) 0;
      }

      &[data-placement="bottom"] {
        margin: var(--gl-spacing-scale-2) 0;
      }

      &[data-placement="left"] {
        margin: 0 var(--gl-spacing-scale-2);
      }

      &[data-placement="right"] {
        margin: 0 var(--gl-spacing-scale-2);
      }

      * {
        pointer-events: all;
      }
    }

    .mq-tour-wrapper {
      position: fixed;
      box-shadow: 0 0 350vw 350vh rgba(0, 0, 0, 0.5);
      border-radius: var(--mq-border-radius-sm);
      border: 2px solid var(--color-brand-indigo);
      z-index: 3;
      pointer-events: none;
    }
  }
}

.mq-tour-wrapper {
  position: relative;
  z-index: 1000;
  pointer-events: none;

  .mq-tour-modal-wrapper {
    pointer-events: auto;
  }

  .mq-tour-modal {
    background-color: var(--color-neutral-surface);
    border-radius: var(--mq-border-radius-sm);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;

    .mq-card {
      width: 100%;
    }

    .mq-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mq-card-body {
      padding: var(--gl-spacing-scale-5);
    }

    .mq-card-footer {
      display: flex;
      justify-content: space-between;
      padding: var(--gl-spacing-scale-5);
    }
  }
}
