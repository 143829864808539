.app-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gl-spacing-scale-3);
  margin-top: var(--gl-spacing-scale-7);

  .app-footer__section {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--gl-spacing-scale-3);
  }

  .app-footer__custom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
    }
  }
}
