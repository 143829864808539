.app-chat-messages {
  width: 100%;
  height: 100%;
  padding: var(--gl-spacing-scale-3);
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  .app-chat-messages__loading {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .app-chat-messages__unread {
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    top: var(--gl-spacing-scale-3);
    z-index: 1;
  }

  &.loading {
    overflow-y: hidden;
  }

  .app-chat-messages__category {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);
    margin-top: var(--gl-spacing-scale-3);

    .app-chat-messages__category-name {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-bottom: 1px solid var(--color-neutral-stroke);
        z-index: -1;
      }

      .app-chat-messages__category-badge {
        position: sticky;
        top: 0;
        padding: var(--gl-spacing-scale-3);
        background-color: var(--color-neutral-surface);
      }
    }
  }

  .app-chat-messages__bottom {
    opacity: 0;
    width: 0;
    height: 0;
  }
}