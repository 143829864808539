.app-chat-emojis {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-surface);
  overflow: hidden;

  .app-chat-emojis__search {
    width: 100%;
    flex-shrink: 0;
    padding: var(--gl-spacing-scale-3);
  }

  .app-chat-emojis__wrapper {
    width: 100%;
    height: 100%;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);
    overflow-y: auto;
    padding: var(--gl-spacing-scale-3);

    .app-chat-emojis__category {
      display: flex;
      flex-direction: column;
      gap: var(--gl-spacing-scale-3);

      .app-chat-emojis__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background-color: var(--color-neutral-surface);

        .app-chat-emojis__item {
          width: 45px;
          height: 45px;
          font-size: var(--mq-font-size-scale-6);
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          cursor: pointer;
          border-radius: var(--mq-border-radius-sm);
          text-align: center;
          justify-content: center;
          padding: 0;

          &:active,
          &:hover {
            background-color: var(--color-neutral-bkg);
          }

          &:active {
            outline: none;
            border: 1px solid var(--color-brand-indigo);
          }
        }
      }
    }
  }
}