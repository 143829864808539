.app-chat-dialog {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--mq-shadow-md);
  border-top-left-radius: var(--mq-border-radius-sm);
  border-top-right-radius: var(--mq-border-radius-sm);
  background-color: var(--color-neutral-surface);
  cursor: pointer;

  .app-chat-dialog__header {
    width: 100%;
    height: 4rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
    background-color: var(--color-neutral-bkg);
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    padding: var(--gl-spacing-scale-3);

    .app-chat-dialog__header-info {
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--gl-spacing-scale-3);
    }

    .app-chat-dialog__header-tools {
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }

  .app-chat-dialog__body {
    width: 100%;
    max-height: 50vh;
    min-height: 20vh;
    padding: var(--gl-spacing-scale-3);
    display: flex;
    flex-direction: column;
    gap: var(--gl-spacing-scale-3);
  }

  .app-chat-dialog__footer {
    width: 100%;
    background-color: var(--color-neutral-bkg);
  }
}