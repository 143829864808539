.mq-nav-divider {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.05);

  &.vertical {
    min-height: 50px;
    height: inherit;
    width: 1px;
  }

  &.horizontal {
    min-width: 50px;
    height: 1px;
    width: inherit;
  }
}