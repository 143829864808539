.mq-slider {
  width: 100%;
  display: flex;
  position: relative;

  &.animate {
    transition: all ease-in-out 0.3s;
  }

  .mq-slider__wrapper {
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    transition: inherit;

    .mq-slider__inner {
      position: relative;
      display: flex;
      min-width: 100%;
      transition: inherit;

      .mq-slider__item {
        position: relative;
        display: flex;
        transition: inherit;
        user-select: inherit;
      }
    }
  }

  &.horizontal {
    .mq-slider__inner {
      height: 100%;
      flex-direction: row;
    }

    .mq-slider__button {
      &.prev {
        left: -2rem;
      }

      &.next {
        right: -2rem;
      }
    }
  }

  &.vertical {
    .mq-slider__inner {
      flex-direction: column;
    }

    .mq-slider__button {
      left: 50%;
      transform: translateX(-50%);

      &.prev {
        top: -0.5rem;
      }

      &.next {
        bottom: -0.5rem;
      }
    }
  }

  .mq-slider__button {
    all: unset;
    cursor: pointer;
    position: absolute;
    height: 4rem;
    width: 4rem;
    z-index: 1;
    background-color: var(--color-brand-blue);
    color: var(--color-neutral-surface);
    transition: inherit;
    box-shadow: var(--mq-shadow-sm);
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;

    &:active,
    &:hover,
    &:focus {
      background-color: var(--color-brand-indigo);
    }

    &:disabled {
      display: none;
    }
  }
}

.mq-slider__dots {
  display: flex;
  justify-content: center;
  margin: auto;

  .mq-slider__dot {
    border: none;
    background: rgba(0, 0, 0, 0.2);
    width: 12px;
    height: 12px;
    margin: 5px;
    border-radius: 50%;
    outline: none;
    cursor: pointer;

    &.active {
      background: rgba(0, 0, 0, 0.8);
    }
  }
}
